import { createTheme } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import { ThemeProvider } from "styled-components"
import ManageUser from "./manage-user"
import Login from "./login"
import Schedule from "./schedule"
import ClubProfile from "./club-profile"
import Settings from "./settings"
import CourtSetting from "./court-settings"
import Billing from "./billing"
import UserProfile from "./user-profile"
import PrivateRoute from "../components/PrivateRoute"
import NotFound from "../components/NotFound"
import { auth } from "../../firebase/firebase-config"
import Loader from "../components/Loader/Loader"

const theme = createTheme({
  "@global": {
    body: { backgroundColor: "white" },
  },
})

const App = () => {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState()

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(delay)
  }, [])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        setIsAuthenticated(user)
      }
    })
    return () => unsubscribe()
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <PrivateRoute
          path="/venue/schedule"
          component={Schedule}
          authenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/venue/manage-user"
          component={ManageUser}
          authenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/venue/club-profile"
          component={ClubProfile}
          authenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/venue/settings"
          component={Settings}
          authenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/venue/court-settings"
          component={CourtSetting}
          authenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/venue/billing"
          component={Billing}
          authenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/venue/user-profile"
          component={UserProfile}
          authenticated={isAuthenticated}
        />
        <Login path="/venue/login" />
        <NotFound default />
      </Router>
    </ThemeProvider>
  )
}

export default App
