import React, { useState } from "react"
import { makeStyles, Typography, Button } from "@material-ui/core"
import DrawerSide from "../../components/DrawerSide"
import BillingCard from "../../components/BillingCard"
import BillingTitle from "../../../images/billingIcon.svg"
import BillingDrawerWrapper from "../../Schedule/Drawer/BillingDrawerWrapper"
import Premium from "../../../../static/images/premium-feature.svg"
import BillingInfoPremium from "../../../images/billingInfoPremium.svg"
import SubscriptionChip from "../../components/SubscriptionChip"
import DeleteConfirmModal from "../../components/modals/DeleteConfirmModal"
import { deletePaymentCard } from "../../../redux/slices/subscriptionModalSlice"
import { setErrorAlert } from "../../../redux/slices/errorAlertSlice"
import { useDispatch, useSelector } from "react-redux"
import {
  activeSubscription,
  cancelSubscription,
} from "../../../redux/slices/subscriptionModalSlice"
import ErrorAlert from "../../components/modals/errorAlert"
import moment from "moment"
import { COLORS } from "../../../utils/theme"
import Loader from "../../components/Loader/Loader"
import { showDeleteConfirmModal } from "../../../redux/slices/deleteConfirmModalSlice"
import Head from "../../components/Head"

const useStyles = makeStyles(theme => ({
  typoTitle: {
    fontSize: "25px",
    fontFamily: "Plus Jakarta Sans",
    color: "#0A3133",
    marginBottom: "20px",
    fontWeight: 400,
  },
  subContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginLeft: "40px",
  },
  contentRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    background: "#F9FAFC",
    border: "1px solid rgb(128 128 128 / 15%)",
    borderRadius: "15px",
  },
  WarapCard: {
    display: "flex",
    columnGap: 11,
    color: "#0a3133ba",
    // minHeight: 150,
  },
  textTitleWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    rowGap: 8,
    marginTop: 10,
  },
  detailWrap: {
    color: "#0A3133",
    display: "flex",
    fontWeight: "400",
    flexDirection: "column",
    rowGap: 8,
    marginTop: 10,
  },
  IconeDive: {
    display: "flex",
    alignItems: "center",
    columnGap: 17,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    rowGap: 10,
  },
  EditTitle: {
    color: "#B4B4BC",
    fontSize: 15,
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

const Billing = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const isVisible = useSelector(
    state => state.deleteConfirmModalSlice.isVisible
  )
  const loading = useSelector(state => state.subscriptionModalSlice.loading)
  const user = useSelector(state => state.userSlice.user)
  const subscription = user?.isSubscribed

  const billingInfo = useSelector(
    state => state.subscriptionModalSlice.billingInfo
  )

  const [drawerOptions, setDrawerOptions] = useState({
    open: false,
    content: "",
    isEdit: false,
  })
  const [deleteModalText, setDeleteModalText] = useState({
    title: "",
    description: "",
    buttonText: "",
  })
  const handleClose = () => {
    setDrawerOptions({ open: false, content: "", isEdit: false })
  }
  const onSubscribe = async () => {
    await dispatch(activeSubscription())
  }
  const handleDelete = () => {
    dispatch(deletePaymentCard())
  }
  const openDeleteConfirmModal = subscription => {
    setDeleteModalText({
      title: subscription
        ? "Are you sure you want to cancel your subscription?"
        : "Are you sure you want to delete this payment card?",
      description: subscription ? "" : "",
      buttonText: subscription ? "Confirm" : "Delete Info",
      onPress: () => {
        subscription ? dispatch(cancelSubscription()) : handleDelete()
      },
    })
    dispatch(showDeleteConfirmModal(true))
  }
  let checkClubOwner = user?.uid == user?.userInfo?.clubOwner
  return (
    <>
      <Head pageTitle="Billing" />
      <DrawerSide>
        <div style={{ width: "100%", marginTop: "40px", padding: "60px" }}>
          <div className={classes.subContent}>
            <Typography className={classes.typoTitle}>Billing</Typography>
          </div>
          <ErrorAlert />
          {loading && <Loader />}
          {!loading && (
            <div className={classes.contentRoot}>
              <BillingCard>
                <div className={classes.ContentWrapper}>
                  <div className={classes.titleWrapper}>
                    <div className={classes.IconeDive}>
                      <img src={BillingTitle} width={50} alt="" />
                      <span>Billing information</span>
                    </div>
                    {billingInfo?.card && (
                      <span
                        className={classes.EditTitle}
                        style={{ marginLeft: "20px" }}
                        onClick={() => openDeleteConfirmModal(false)}
                      >
                        Delete
                      </span>
                    )}
                  </div>
                  <div className={classes.WarapCard}>
                    <div className={classes.textTitleWrap}>
                      <span>Company name:</span>
                      {billingInfo?.card && (
                        <>
                          <span>Card:</span>
                          <span>Card Number:</span>
                          <span>Country:</span>
                          <span>Exp:</span>
                        </>
                      )}
                      <span>Address:</span>
                    </div>
                    <div className={classes.detailWrap}>
                      <span>{user?.userInfo?.clubName} &nbsp;</span>
                      {billingInfo?.card && (
                        <>
                          <span>{billingInfo?.card?.brand}</span>

                          <span>{`***********${billingInfo?.card?.last4}`}</span>
                          <span>{billingInfo?.card?.country}</span>
                          <span>
                            {billingInfo?.card?.exp_month +
                              "/" +
                              billingInfo?.card?.exp_year}
                          </span>
                        </>
                      )}
                      {/* <span>{billingInfo?.card}</span> */}
                      {user?.userInfo?.address}
                    </div>
                  </div>
                  {!billingInfo?.card && checkClubOwner && (
                    <Button
                      onClick={() =>
                        setDrawerOptions({
                          open: true,
                          content: "PaymentForm",
                          isEdit: false,
                        })
                      }
                      style={{
                        backgroundColor: "#45C165",
                        color: "white",
                        textTransform: "capitalize",
                        borderRadius: "12px 12px",
                        width: "50%",
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      Add Card
                    </Button>
                  )}
                </div>
              </BillingCard>

              <BillingCard>
                <div className={classes.ContentWrapper}>
                  <div className={classes.titleWrapper}>
                    <div className={classes.IconeDive}>
                      <img src={BillingInfoPremium} width={50} />
                      <span>Subscription plan </span>
                    </div>
                    {checkClubOwner && (
                      <span
                        className={classes.EditTitle}
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                          if (!subscription && billingInfo?.card) {
                            onSubscribe()
                          } else if (
                            subscription &&
                            billingInfo?.status == "trialing"
                          ) {
                            !billingInfo?.card
                              ? dispatch(
                                  setErrorAlert({
                                    isVisible: true,
                                    message: "Add Card Detail",
                                    title: "error",
                                    severity: "error",
                                  })
                                )
                              : onSubscribe()
                          } else if (
                            subscription &&
                            billingInfo?.status != "trialing"
                          ) {
                            openDeleteConfirmModal(true)
                          }
                          // subscription ? null : dispatch(showSubscriptionModal(true))
                        }}
                      >
                        {subscription && billingInfo?.status != "trialing"
                          ? "Cancel plan"
                          : "Upgrade plan"}
                      </span>
                    )}
                  </div>

                  <div className={classes.WarapCard}>
                    <div className={classes.textTitleWrap}>
                      <span>Current plan:</span>
                      <span style={{ marginTop: "6px" }}>Price:</span>
                      <span style={{ marginTop: "1px" }}>Next payment:</span>
                    </div>
                    <div className={classes.detailWrap}>
                      <SubscriptionChip
                        label={
                          subscription && billingInfo?.status == "trialing"
                            ? "Racqy Plus (Free Trial)"
                            : subscription && billingInfo?.status != "trialing"
                            ? "Racqy Plus"
                            : "Racqy Free"
                        }
                        hideImage={!subscription}
                        notClickable={true}
                        textStyle={{
                          color: subscription ? "black" : COLORS.white,
                          fontWeight: "normal",
                        }}
                        style={{
                          position: "relative",
                          width:
                            subscription && billingInfo?.status == "trialing"
                              ? "200px"
                              : "120px",
                          right: 0,
                          backgroundColor: subscription
                            ? "#EAFADD"
                            : COLORS.darkGray4,
                        }}
                      />

                      <span>
                        {subscription && billingInfo?.status == "trialing"
                          ? `Free trial until ${moment(
                              billingInfo?.trial_end * 1000
                            ).format("D MMMM")}`
                          : subscription
                          ? "$" + billingInfo?.price?.unit_amount / 100
                          : "Free"}
                      </span>
                      <span>
                        {subscription && billingInfo?.status == "trialing"
                          ? "-"
                          : subscription
                          ? moment(
                              billingInfo?.endDate?._seconds ||
                                billingInfo?.endDate
                            ).format("DD MMMM")
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </BillingCard>
            </div>
          )}
          {isVisible && (
            <DeleteConfirmModal
              header={deleteModalText?.title}
              info={deleteModalText?.description}
              buttonTitle={deleteModalText?.buttonText}
              onClick={() => deleteModalText?.onPress()}
              loading={loading}
            />
          )}
        </div>

        <BillingDrawerWrapper
          handleClose={handleClose}
          open={drawerOptions.open}
          content={drawerOptions.content}
          isEdit={drawerOptions.isEdit}
        />
      </DrawerSide>
    </>
  )
}
export default Billing
