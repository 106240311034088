import { makeStyles } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
  shadowBox: {
    margin: "30px",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 5%) 0px 0px 0px 1px",
    width: "480px",
    // display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "30px",
    borderRadius: "10px",
    barder: "1px solid #EAEAED",
    paddingLeft: "10px",
    minHeight: 200,

    "& .titleGray": {
      color: "#000000",
      fontSize: "15px",
      fontFamily: "DM Sans",
      fontWeight: 300,
    },
    background: "white",
    "& .imgGray": {
      width: "60px",
      marginRight: "10px",
    },
    "& .contentGray": {
      color: "#B4B4BC",
      fontSize: "12px",
      fontFamily: "DM Sans",
      fontWeight: 300,
      textAlign: "left",
      lineHeight: "25px",
    },
  },
}))

function BillingCard({ children }) {
  const classes = useStyles()

  const billingInfo = useSelector(
    state => state.subscriptionModalSlice.billingInfo
  )
  const isSubscribed = useSelector(state => state.userSlice.user?.isSubscribed)
  return (
    <div>
      <div
        className={classes.shadowBox}
        style={{ height: isSubscribed || billingInfo?.card ? "270px" : "" }}
      >
        {children}
      </div>
    </div>
  )
}

export default BillingCard
